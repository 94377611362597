const TextInputSection = ({ section, fio, setFio }) => (
  <div className="mb-2 bg-white p-2">
    <h2 className="text-lg md:text-xl font-semibold mb-2">{section.header}</h2>
    <p className="text-gray-600 text-sm md:text-base mb-2">{section.text}</p>
    {section.type === "textInput" && (
      <input
        type="text"
        value={fio}
        onChange={(e) => setFio(e.target.value)}
        className="w-full border border-gray-300 rounded-lg p-2 text-base md:text-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
        placeholder="Введите ваше ФИО"
        required
      />
    )}
  </div>
);

export default TextInputSection;
