export const jovashiTestData = [
  {
    id: 1,
    text: "Мне хотелось бы в своей профессиональной деятельности",
    options: [
      { id: "a", text: "общаться с самыми разными людьми", type: "I" },
      {
        id: "b",
        text: "снимать фильмы, писать книги, рисовать, выступать на сцене и т.д.",
        type: "IV",
      },
      { id: "c", text: "заниматься расчетами; вести документацию", type: "VI" },
    ],
    maxChoices: 1,
  },
  {
    id: 2,
    text: "В книге или кинофильме меня больше всего привлекает",
    options: [
      {
        id: "a",
        text: "возможность следить за ходом мыслей автора",
        type: "II",
      },
      {
        id: "b",
        text: "художественная форма, мастерство писателя или режиссера",
        type: "IV",
      },
      { id: "c", text: "сюжет, действия героев", type: "V" },
    ],
    maxChoices: 1,
  },
  {
    id: 3,
    text: "Меня больше обрадует Нобелевская премия",
    options: [
      { id: "a", text: "за общественную деятельность", type: "I" },
      { id: "b", text: "в области науки", type: "II" },
      { id: "c", text: "в области искусства", type: "IV" },
    ],
    maxChoices: 1,
  },
  {
    id: 4,
    text: "Я скорее соглашусь стать",
    options: [
      { id: "a", text: "главным механиком", type: "III" },
      { id: "b", text: "начальником экспедиции", type: "V" },
      { id: "c", text: "главным бухгалтером", type: "VI" },
    ],
    maxChoices: 1,
  },
  {
    id: 5,
    text: "Будущее людей определяют",
    options: [
      { id: "a", text: "взаимопонимание между людьми", type: "I" },
      { id: "b", text: "научные открытия", type: "II" },
      { id: "c", text: "развитие производства", type: "III" },
    ],
    maxChoices: 1,
  },
  {
    id: 6,
    text: "Если я стану руководителем, то в первую очередь займусь",
    options: [
      {
        id: "a",
        text: "созданием дружного, сплоченного коллектива",
        type: "I",
      },
      { id: "b", text: "разработкой новых технологий обучения", type: "II" },
      { id: "c", text: "работой с документами", type: "VI" },
    ],
    maxChoices: 1,
  },
  {
    id: 7,
    text: "На технической выставке меня больше привлечет",
    options: [
      { id: "a", text: "внутреннее устройство экспонатов", type: "II" },
      { id: "b", text: "их практическое применение", type: "III" },
      { id: "c", text: "внешний вид экспонатов (цвет, форма)", type: "IV" },
    ],
    maxChoices: 1,
  },
  {
    id: 8,
    text: "В людях я ценю, прежде всего",
    options: [
      { id: "a", text: "дружелюбие и отзывчивость", type: "I" },
      { id: "b", text: "смелость и выносливость", type: "V" },
      { id: "c", text: "обязательность и аккуратность", type: "VI" },
    ],
    maxChoices: 1,
  },
  {
    id: 9,
    text: "В свободное время мне хотелось бы",
    options: [
      { id: "a", text: "ставить различные опыты, эксперименты", type: "II" },
      {
        id: "b",
        text: "писать стихи, сочинять музыку или рисовать",
        type: "IV",
      },
      { id: "c", text: "тренироваться", type: "V" },
    ],
    maxChoices: 1,
  },
  {
    id: 10,
    text: "В заграничных поездках меня скорее заинтересует",
    options: [
      {
        id: "a",
        text: "возможность знакомства с историей и культурой другой страны",
        type: "IV",
      },
      {
        id: "b",
        text: "экстремальный туризм (альпинизм, виндсерфинг, горные лыжи)",
        type: "V",
      },
      { id: "c", text: "деловое общение", type: "VI" },
    ],
    maxChoices: 1,
  },
  {
    id: 11,
    text: "Мне интереснее беседовать о",
    options: [
      { id: "a", text: "человеческих взаимоотношениях", type: "I" },
      { id: "b", text: "новой научной гипотезе", type: "II" },
      {
        id: "c",
        text: "технических характеристиках новой модели машины, компьютера",
        type: "III",
      },
    ],
    maxChoices: 1,
  },
  {
    id: 12,
    text: "Если бы в моей школе было всего три кружка, я бы выбрал",
    options: [
      { id: "a", text: "технический", type: "III" },
      { id: "b", text: "музыкальный", type: "IV" },
      { id: "c", text: "спортивный", type: "V" },
    ],
    maxChoices: 1,
  },
  {
    id: 13,
    text: "В школе следует обратить особое внимание на",
    options: [
      {
        id: "a",
        text: "улучшение взаимопонимания между учителями и учениками",
        type: "I",
      },
      {
        id: "b",
        text: "поддержание здоровья учащихся, занятия спортом",
        type: "V",
      },
      { id: "c", text: "укрепление дисциплины", type: "VI" },
    ],
    maxChoices: 1,
  },
  {
    id: 14,
    text: "Я с большим удовольствием смотрю",
    options: [
      { id: "a", text: "научно-популярные фильмы", type: "II" },
      { id: "b", text: "программы о культуре и искусстве", type: "IV" },
      { id: "c", text: "спортивные программы", type: "V" },
    ],
    maxChoices: 1,
  },
  {
    id: 15,
    text: "Мне хотелось бы работать",
    options: [
      { id: "a", text: "с детьми или сверстниками", type: "I" },
      { id: "b", text: "с машинами, механизмами", type: "III" },
      { id: "c", text: "с объектами природы", type: "V" },
    ],
    maxChoices: 1,
  },
  {
    id: 16,
    text: "Школа в первую очередь должна",
    options: [
      { id: "a", text: "учить общению с другими людьми", type: "I" },
      { id: "b", text: "давать знания", type: "III" },
      { id: "c", text: "обучать навыкам работы", type: "VI" },
    ],
    maxChoices: 1,
  },
  {
    id: 17,
    text: "Главное в жизни",
    options: [
      { id: "a", text: "иметь возможность заниматься творчеством", type: "IV" },
      { id: "b", text: "вести здоровый образ жизни", type: "V" },
      { id: "c", text: "тщательно планировать свои дела", type: "VI" },
    ],
    maxChoices: 1,
  },
  {
    id: 18,
    text: "Государство должно в первую очередь заботиться о",
    options: [
      { id: "a", text: "защите интересов и прав граждан", type: "I" },
      { id: "b", text: "достижениях в области науки и техники", type: "II" },
      { id: "c", text: "материальном благополучии граждан", type: "III" },
    ],
    maxChoices: 1,
  },
  {
    id: 19,
    text: "Мне больше всего нравятся уроки",
    options: [
      { id: "a", text: "труда", type: "III" },
      { id: "b", text: "физкультуры", type: "V" },
      { id: "c", text: "математики", type: "VI" },
    ],
    maxChoices: 1,
  },
  {
    id: 20,
    text: "Мне интереснее было бы",
    options: [
      { id: "a", text: "заниматься сбытом товаров", type: "I" },
      { id: "b", text: "изготавливать изделия", type: "III" },
      { id: "c", text: "планировать производство товаров", type: "VI" },
    ],
    maxChoices: 1,
  },
  {
    id: 21,
    text: "Я предпочитаю читать статьи о",
    options: [
      { id: "a", text: "выдающихся ученых и их открытиях", type: "II" },
      { id: "b", text: "интересных изобретениях", type: "III" },
      {
        id: "c",
        text: "жизни и творчестве писателей, художников, музыкантов",
        type: "IV",
      },
    ],
    maxChoices: 1,
  },
  {
    id: 22,
    text: "Свободное время я люблю",
    options: [
      { id: "a", text: "читать, думать, рассуждать", type: "II" },
      {
        id: "b",
        text: "что-нибудь мастерить, шить, ухаживать за животными, растениями",
        type: "III",
      },
      { id: "c", text: "ходить на выставки, концерты, в музеи", type: "IV" },
    ],
    maxChoices: 1,
  },
  {
    id: 23,
    text: "Больший интерес у меня вызовет сообщение о",
    options: [
      { id: "a", text: "научном открытии", type: "II" },
      { id: "b", text: "художественной выставке", type: "IV" },
      { id: "c", text: "экономической ситуации", type: "VI" },
    ],
    maxChoices: 1,
  },
  {
    id: 24,
    text: "Я предпочту работать",
    options: [
      { id: "a", text: "в помещении, где много людей", type: "I" },
      { id: "b", text: "в необычных условиях", type: "V" },
      { id: "c", text: "в обычном кабинете", type: "VI" },
    ],
    maxChoices: 1,
  },
];

export const sendInfo = {
  serviceId: "service_w0p654s",
  templateId: "template_t1c3bp4",
  publicKey: "5ztFKSbFCws8OwMkK",
};
