export const hollandTestData = [
  {
    id: "fio",
    type: "textInput",
    header: "ФИО",
    text: "Пожалуйста, введите ваше ФИО:",
  },
  {
    id: "instructions",
    type: "textParagraph",
    header: "Инструкция",
    text: "Из каждой пары профессий выберите одну, наиболее привлекательную для вас.",
  },
  {
    id: "questions",
    type: "multiCheckbox",
    questions: [
      {
        id: 1,
        text: "Автомеханик или Физиотерапевт",
        options: [
          { id: "A", text: "Автомеханик", type: "Реалистический" },
          { id: "B", text: "Физиотерапевт", type: "Социальный" },
        ],
      },
      {
        id: 2,
        text: "Специалист по защите информации или Логистик",
        options: [
          {
            id: "A",
            text: "Специалист по защите информации",
            type: "Интеллектуальный",
          },
          { id: "B", text: "Логистик", type: "Предприимчивый" },
        ],
      },
      {
        id: 3,
        text: "Оператор связи или Кинооператор",
        options: [
          { id: "A", text: "Оператор связи", type: "Офисный" },
          { id: "B", text: "Кинооператор", type: "Артистический" },
        ],
      },
      {
        id: 4,
        text: "Водитель или Кассир",
        options: [
          { id: "A", text: "Водитель", type: "Реалистический" },
          { id: "B", text: "Кассир", type: "Социальный" },
        ],
      },
      {
        id: 5,
        text: "Инженер-конструктор или Менеджер по автопродажам",
        options: [
          { id: "A", text: "Инженер-конструктор", type: "Интеллектуальный" },
          { id: "B", text: "Менеджер по автопродажам", type: "Предприимчивый" },
        ],
      },
      {
        id: 6,
        text: "Диспетчер или Дизайнер компьютерных программ",
        options: [
          { id: "A", text: "Диспетчер", type: "Офисный" },
          {
            id: "B",
            text: "Дизайнер компьютерных программ",
            type: "Артистический",
          },
        ],
      },
      {
        id: 7,
        text: "Ветеринар или Эколог",
        options: [
          { id: "A", text: "Ветеринар", type: "Реалистический" },
          { id: "B", text: "Эколог", type: "Артистический" },
        ],
      },
      {
        id: 8,
        text: "Биолог-исследователь или Фермер",
        options: [
          { id: "A", text: "Биолог-исследователь", type: "Интеллектуальный" },
          { id: "B", text: "Фермер", type: "Предприимчивый" },
        ],
      },
      {
        id: 9,
        text: "Лаборант или Дрессировщик",
        options: [
          { id: "A", text: "Лаборант", type: "Офисный" },
          { id: "B", text: "Дрессировщик", type: "Артистический" },
        ],
      },
      {
        id: 10,
        text: "Агроном или Санитарный Врач",
        options: [
          { id: "A", text: "Агроном", type: "Реалистический" },
          { id: "B", text: "Санитарный врач", type: "Социальный" },
        ],
      },
      {
        id: 11,
        text: "Селекционер или Заготовитель сельхозпродуктов",
        options: [
          { id: "A", text: "Селекционер", type: "Интеллектуальный" },
          {
            id: "B",
            text: "Заготовитель сельхозпродуктов",
            type: "Предприимчивый",
          },
        ],
      },
      {
        id: 12,
        text: "Санитарный инспектор или Ландшафтный дизайнер",
        options: [
          { id: "A", text: "Санитарный инспектор", type: "Офисный" },
          { id: "B", text: "Ландшафтный дизайнер", type: "Артистический" },
        ],
      },
      {
        id: 13,
        text: "Массажист или воспитатель",
        options: [
          { id: "A", text: "Массажист", type: "Реалистический" },
          { id: "B", text: "Воспитатель", type: "Социальный" },
        ],
      },
      {
        id: 14,
        text: "Преподаватель или Предприниматель",
        options: [
          { id: "A", text: "Преподаватель", type: "Интеллектуальный" },
          { id: "B", text: "Предприниматель", type: "Предприимчивый" },
        ],
      },
      {
        id: 15,
        text: "Администратор или Режиссер театра и Кино",
        options: [
          { id: "A", text: "Администратор", type: "Офисный" },
          { id: "B", text: "Режиссер театра и Кино", type: "Артистический" },
        ],
      },
      {
        id: 16,
        text: "Официант или врач",
        options: [
          { id: "A", text: "Официант", type: "Реалистический" },
          { id: "B", text: "Врач", type: "Социальный" },
        ],
      },
      {
        id: 17,
        text: "Психолог или тороговый агент",
        options: [
          { id: "A", text: "Психолог", type: "Интеллектуальный" },
          { id: "B", text: "Торговый агент", type: "Предприимчивый" },
        ],
      },
      {
        id: 18,
        text: "Страховой агент или Хореограф",
        options: [
          { id: "A", text: "Страховой агент ", type: "Офисный" },
          { id: "B", text: "Хореограф", type: "Артистический" },
        ],
      },
      {
        id: 19,
        text: "Ювелир-гравер или Журналист",
        options: [
          { id: "A", text: "Ювелир-гравер", type: "Реалистический" },
          { id: "B", text: "Журналист", type: "Социальный" },
        ],
      },
      {
        id: 20,
        text: "Искусствовед или Продюсер",
        options: [
          { id: "A", text: "Искусствовед", type: "Интеллектуальный" },
          { id: "B", text: "Продюсер", type: "Предприимчивый" },
        ],
      },
      {
        id: 21,
        text: "Редактор или Музыкант",
        options: [
          { id: "A", text: "Редактор", type: "Офисный" },
          { id: "B", text: "Музыкант", type: "Артистический" },
        ],
      },
      {
        id: 22,
        text: "Дизайнер интерьера или Экскурсовод",
        options: [
          { id: "A", text: "Дизайнер интерьера", type: "Реалистический" },
          { id: "B", text: "Экскурсовод", type: "Социальный" },
        ],
      },
      {
        id: 23,
        text: "Композитор или Арт-директор",
        options: [
          { id: "A", text: "Композитор", type: "Интеллектуальный" },
          { id: "B", text: "Арт-директор", type: "Предприимчивый" },
        ],
      },
      {
        id: 24,
        text: "Музейный работник или Актер театра и кино",
        options: [
          { id: "A", text: "Музейный работник", type: "Офисный" },
          { id: "B", text: "Актер театра и кино", type: "Артистический" },
        ],
      },
      {
        id: 25,
        text: "Верстальщик или Гид-переводчик",
        options: [
          { id: "A", text: "Верстальщик", type: "Реалистический" },
          { id: "B", text: "Гид-переводчик", type: "Социальный" },
        ],
      },
      {
        id: 26,
        text: "Лингвист или Антикризисный управляющий",
        options: [
          { id: "A", text: "Лингвист", type: "Интеллектуальный" },
          {
            id: "B",
            text: "Антикризисный управляющий",
            type: "Предприимчивый",
          },
        ],
      },
      {
        id: 27,
        text: "Корректор или Художественный редактор",
        options: [
          { id: "A", text: "Корректор", type: "Офисный" },
          { id: "B", text: "Художественный редактор", type: "Артистический" },
        ],
      },
      {
        id: 28,
        text: "Наборщик текстов или Юрисконсульт ",
        options: [
          { id: "A", text: "Наборщик текстов", type: "Реалистический" },
          { id: "B", text: "Юрисконсульт", type: "Социальный" },
        ],
      },
      {
        id: 29,
        text: "Программист или брокер",
        options: [
          { id: "A", text: "Программист", type: "Интеллектуальный" },
          { id: "B", text: "Брокер", type: "Предприимчивый" },
        ],
      },
      {
        id: 30,
        text: "Бухгалтер или Литературный переводчик ",
        options: [
          { id: "A", text: "Бухгалтер", type: "Офисный" },
          { id: "B", text: "Литературный переводчик", type: "Артистический" },
        ],
      },
    ],
  },
];

export const sendInfo = {
  serviceId: "service_w0p654s",
  templateId: "template_t1c3bp4",
  publicKey: "5ztFKSbFCws8OwMkK",
};
