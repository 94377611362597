import { useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import emailjs from "@emailjs/browser";

import { setResultObject } from "../../../../store/reducers/FormReducer";
import { checkAllValues } from "../helpers/checkers";

export const useSendButton = (inputs, sendInfo, analysis) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sendingForm = useRef(null);

  const { resultObject } = useSelector((state) => state.form);

  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    if (checkAllValues(inputs)) {
      const resultObject = analysis(inputs);
      dispatch(setResultObject(resultObject));
      setIsOpen(true);
    } else {
      alert("Заполните все поля");
    }
  };

  const send = (event) => {
    event.preventDefault();

    const { serviceId, templateId, publicKey } = sendInfo;

    emailjs
      .sendForm(serviceId, templateId, sendingForm.current, publicKey)
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        },
      );

    close();
    navigate("/finish");
  };

  const close = () => {
    setIsOpen(false);
  };

  const formInputs = [
    {
      id: 0,
      name: "name",
      value: resultObject.name,
    },
    {
      id: 1,
      name: "result",
      value: resultObject.result,
    },
  ];

  return {
    isOpen,
    open,
    send,
    close,
    sendingForm,
    formInputs,
  };
};
