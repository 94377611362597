import React from "react";

const QuestionSection = ({ section, answers, onAnswerChange }) => (
  <div className="space-y-6">
    {section.questions.map((question) => (
      <React.Fragment key={question.id}>
        {" "}
        {question.id === 6 && (
          <p className="text-lg font-bold mb-3">
            Как Вы относитесь к перечисленным ниже источникам дохода? ДАЙТЕ
            ТОЛЬКО ОДИН ОТВЕТ В КАЖДОМ ПУНКТЕ.
          </p>
        )}
        <div className="bg-white p-4 md:p-6 rounded-2xl shadow-md border border-gray-200">
          <p className="font-semibold text-lg md:text-xl mb-3">
            {question.id}. {question.text}
          </p>
          <div className="space-y-2">
            {question.options.map((option) => (
              <label
                key={option.id}
                className="flex items-center space-x-3 bg-gray-100 p-3 rounded-lg hover:bg-gray-200 transition cursor-pointer"
              >
                <input
                  type={question.maxChoices === 1 ? "radio" : "checkbox"}
                  name={`question-${question.id}`}
                  maxLength={question.maxChoices}
                  value={option.id}
                  onChange={() => onAnswerChange(question.id, option.id)}
                  checked={(answers[question.id] || []).includes(option.id)}
                  className="w-5 h-5 flex-shrink-0 accent-blue-600"
                />
                <span className="text-base md:text-lg text-gray-700 leading-snug">
                  {option.text}
                </span>
              </label>
            ))}
          </div>
        </div>
      </React.Fragment>
    ))}
  </div>
);

export default QuestionSection;
