import React from 'react';
import Navbar from '../components/Navbar';
import HollandForm from '../components/HollandTest/HollandForm';

const HollandTestPage = () => {
  return (
    <div className="background">
      <Navbar />
      <HollandForm />
    </div>
  );
};

export default HollandTestPage;