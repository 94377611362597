export const trudMotivTestData = [
  {
    id: "fio",
    type: "textInput",
    header: "ФИО",
    text: "Пожалуйста, введите ваше ФИО:",
  },
  {
    id: "instructions",
    type: "textParagraph",
    header: "Инструкция",
    text:
      "Внимательно прочитайте вопрос и все варианты ответов, которые для него приведены.\n" +
      "В каждом вопросе есть указание, сколько вариантов ответа Вы можете дать.\n" +
      "Большинство вопросов требуют дать только один или два варианта ответа. \n",
  },
  {
    id: "questions",
    type: "multiCheckbox",
    questions: [
      {
        id: 1,
        text: "Что Вы больше всего цените в своей работе?\nМожете дать ОДИН ИЛИ ДВА варианта ответа:",
        options: [
          {
            id: "1",
            text: "Что я в основном сам решаю, что и как мне делать.",
            type: "ow",
          },
          {
            id: "2",
            text: "Что она дает мне возможность проявить то, что я знаю и умею.",
            type: "pr",
          },
          {
            id: "3",
            text: "Что я чувствую себя полезным и нужным.",
            type: "pa",
          },
          {
            id: "4",
            text: "Что мне за нее относительно неплохо платят.",
            type: "in",
          },
          {
            id: "5",
            text: "Особенно ничего не ценю, но эта работа мне хорошо знакома и привычна.",
            type: "lu",
          },
        ],
        maxChoices: 2,
      },
      {
        id: 2,
        text: "Какое выражение Вам подходит более всего?\nДайте ТОЛЬКО ОДИН ответ:",
        options: [
          {
            id: "1",
            text: "Я хочу обеспечить своим трудом себе и своей семье приличный доход.",
            type: "in",
          },
          { id: "2", text: "В своей работе я – полный хозяин.", type: "ow" },
          {
            id: "3",
            text: "У меня достаточно знаний и опыта, чтобы справиться с любыми трудностями в моей работе.",
            type: "pr",
          },
          {
            id: "4",
            text: "Я - ценный, незаменимый для организации работник.",
            type: "pa",
          },
          {
            id: "5",
            text: "Я всегда выполняю то, что от меня требуют.",
            type: "lu",
          },
        ],
        maxChoices: 1,
      },
      {
        id: 3,
        text: "Как Вы предпочитаете работать?\nМожете дать ОДИН ИЛИ ДВА варианта ответа:",
        options: [
          {
            id: "1",
            text: "Предпочитаю делать то, что знакомо, привычно.",
            type: "lu",
          },
          {
            id: "2",
            text: "Нужно, чтобы в работе постоянно появлялось что-то новое, чтобы не стоять на месте.",
            type: "pr",
          },
          {
            id: "3",
            text: "Чтобы было точно известно, что нужно сделать и что я за это получу.",
            type: "in",
          },
          {
            id: "4",
            text: "Предпочитаю работать под полную личную ответственность.",
            type: "ow",
          },
          {
            id: "5",
            text: "Готов(а) делать все, что нужно для организации.",
            type: "pa",
          },
        ],
        maxChoices: 2,
      },
      {
        id: 4,
        text: "Допустим, что Вам предлагают другую работу в Вашей организации. При каких условиях Вы бы на это согласились?\nМожете дать ОДИН ИЛИ ДВА варианта ответа:",
        options: [
          {
            id: "1",
            text: "Если предложат намного более высокую зарплату.",
            type: "in",
          },
          {
            id: "2",
            text: "Если другая работа будет более творческой, интересней, чем нынешняя.",
            type: "pr",
          },
          {
            id: "3",
            text: "Если новая работа даст мне больше самостоятельности.",
            type: "ow",
          },
          {
            id: "4",
            text: "Если это очень нужно для организации.",
            type: "pa",
          },
          {
            id: "5",
            text: "При всех случаях я предпочел (предпочла) бы остаться на той работе, к которой привык (привыкла).",
            type: "lu",
          },
        ],
        maxChoices: 2,
      },
      {
        id: 5,
        text: "Попробуйте определить, что для Вас означает Ваш заработок?\nМожете дать ОДИН ИЛИ ДВА варианта ответа:",
        options: [
          {
            id: "1",
            text: "Плата за время и усилия, потраченные на выполнение работы.",
            type: "in",
          },
          {
            id: "2",
            text: "Это, прежде всего, плата за мои знания, квалификацию.",
            type: "pr",
          },
          {
            id: "3",
            text: "Оплата за общие результаты деятельности организации.",
            type: "pa",
          },
          {
            id: "4",
            text: "Мне нужен гарантированный заработок - пусть небольшой, но чтобы он был.",
            type: "lu",
          },
          {
            id: "5",
            text: "Какой бы он ни был, я его заработал(а) сам(а).",
            type: "ow",
          },
        ],
        maxChoices: 2,
      },
      {
        id: 6,
        text: "Заработная плата и премии\nДайте ТОЛЬКО ОДИН ответ:",
        options: [
          { id: "1", text: "Очень важно" },
          { id: "2", text: "Не очень важно" },
          { id: "3", text: "Совсем не важно" },
        ],
        maxChoices: 1,
      },
      {
        id: 7,
        text: "Доплаты за квалификацию\nДайте ТОЛЬКО ОДИН ответ:",
        options: [
          { id: "1", text: "Очень важно", type: ["pr", "in"] },
          { id: "2", text: "Не очень важно" },
          { id: "3", text: "Совсем не важно", type: ["pa", "ow"] },
        ],
        maxChoices: 1,
      },
      {
        id: 8,
        text: "Доплаты за тяжелые и вредные условия работы\nДайте ТОЛЬКО ОДИН ответ:",
        options: [
          { id: "1", text: "Очень важно", type: ["in", "lu"] },
          { id: "2", text: "Не очень важно" },
          { id: "3", text: "Совсем не важно", type: ["pr", "pa"] },
        ],
        maxChoices: 1,
      },
      {
        id: 9,
        text: "Социальные выплаты и льготы, пособия\nДайте ТОЛЬКО ОДИН ответ:",
        options: [
          { id: "1", text: "Очень важно", type: "lu" },
          { id: "2", text: "Не очень важно" },
          { id: "3", text: "Совсем не важно", type: "pr" },
        ],
        maxChoices: 1,
      },
      {
        id: 10,
        text: "Доходы от капитала, акций\nДайте ТОЛЬКО ОДИН ответ:",
        options: [
          { id: "1", text: "Очень важно", type: "ow" },
          { id: "2", text: "Не очень важно" },
          { id: "3", text: "Совсем не важно", type: ["in", "pr", "pa", "lu"] },
        ],
        maxChoices: 1,
      },
      {
        id: 11,
        text: "Любые дополнительные приработки\nДайте ТОЛЬКО ОДИН ответ:",
        options: [
          { id: "1", text: "Очень важно", type: ["in", "lu"] },
          { id: "2", text: "Не очень важно" },
          { id: "3", text: "Совсем не важно", type: "pa" },
        ],
        maxChoices: 1,
      },
      {
        id: 12,
        text: "Приработки, но не любые, а только по своей специальности\nДайте ТОЛЬКО ОДИН ответ:",
        options: [
          { id: "1", text: "Очень важно", type: ["in", "pr"] },
          { id: "2", text: "Не очень важно" },
          { id: "3", text: "Совсем не важно", type: ["ow", "lu"] },
        ],
        maxChoices: 1,
      },
      {
        id: 13,
        text: "Доходы от Личного хозяйства, дачного участка и т.п.\nДайте ТОЛЬКО ОДИН ответ:",
        options: [
          { id: "1", text: "Очень важно", type: "ow" },
          { id: "2", text: "Не очень важно" },
          { id: "3", text: "Совсем не важно" },
        ],
        maxChoices: 1,
      },
      {
        id: 14,
        text: "Выигрыш в лотерею, казино и пр.\nДайте ТОЛЬКО ОДИН ответ:",
        options: [
          { id: "1", text: "Очень важно", type: "lu" },
          { id: "2", text: "Не очень важно" },
          { id: "3", text: "Совсем не важно", type: ["in", "pr"] },
        ],
        maxChoices: 1,
      },
      {
        id: 15,
        text: "На каких принципах, по-Вашему, должны строиться отношения между работником и организацией? Дайте ТОЛЬКО ОДИН ответ:",
        options: [
          {
            id: "1",
            text: "Работник должен относиться к организации, как к своему дому, отдавать ей все и вместе переживать трудности и подъемы. Организация должна соответственно оценивать преданность и труд работника.",
            type: ["pa", "ow"],
          },
          {
            id: "2",
            text: "Работник продает организации свой труд, и если ему не дают хорошую цену, он вправе найти другого покупателя.",
            type: "in",
          },
          {
            id: "3",
            text: "Работник приходит в организацию для самореализации и относится к ней, как к месту реализации своих способностей. Организация должна обеспечивать работнику такую возможность, извлекать из этого выгоду для себя и на этой основе развиваться.",
            type: "pr",
          },
          {
            id: "4",
            text: "Работник тратит на организацию свои силы, а организация должна взамен гарантировать ему зарплату и социальные блага.",
            type: "lu",
          },
        ],
        maxChoices: 1,
      },
      {
        id: 16,
        text: "Как Вы считаете, почему в процессе работы люди проявляют инициативу, вносят различные предложения? Можете дать ОДИН ИЛИ ДВА варианта ответа:",
        options: [
          {
            id: "1",
            text: "Чувствуют особую ответственность за свою работу.",
            type: "ow",
          },
          {
            id: "2",
            text: "Из-за стремления реализовать свои знания и опыт, выйти за установленные работой рамки.",
            type: "pr",
          },
          {
            id: "3",
            text: "Чаще всего из-за желания улучшить работу своей организации.",
            type: "pa",
          },
          {
            id: "4",
            text: "Просто хотят «выделиться» или завоевать расположение начальства.",
            type: "lu",
          },
          {
            id: "5",
            text: "Хотят заработать, поскольку всякая полезная инициатива должна вознаграждаться.",
            type: "in",
          },
        ],
        maxChoices: 2,
      },
      {
        id: 17,
        text: "Какое суждение о коллективной работе Вам ближе? Можете дать ОДИН ИЛИ ДВА варианта ответа:",
        options: [
          {
            id: "1",
            text: "Коллектив для меня очень важен, одному хороших результатов не добиться.",
            type: "pa",
          },
          {
            id: "2",
            text: "Предпочитаю работать автономно, но чувствую себя так же хорошо, когда работаю вместе с интересными людьми.",
            type: "pr",
          },
          {
            id: "3",
            text: "Мне нужна свобода действий, а коллектив чаще всего эту свободу ограничивает.",
            type: "ow",
          },
          {
            id: "4",
            text: "Можно работать и в коллективе, но платить должны по личным результатам.",
            type: "in",
          },
          {
            id: "5",
            text: "Мне нравится работать в коллективе, так как там я среди своих.",
            type: "lu",
          },
        ],
        maxChoices: 2,
      },
      {
        id: 18,
        text: "Представьте себе, что у вас появилась возможность стать владельцем Вашей организации. Воспользуетесь ли Вы этой возможностью? Можете дать ОДИН ИЛИ ДВА варианта ответа:",
        options: [
          {
            id: "1",
            text: "Да, так как я смогу участвовать в управлении организацией.",
            type: ["pa", "ow"],
          },
          {
            id: "2",
            text: "Да, потому что это может увеличить мой доход.",
            type: "in",
          },
          {
            id: "3",
            text: "Да, так как настоящий работник должен быть совладельцем.",
            type: "ow",
          },
          {
            id: "4",
            text: "Вряд ли: на заработке это не скажется участие в управлении меня не интересует, а работе это помешает.",
            type: "pr",
          },
          { id: "5", text: "Нет, не нужны мне лишние заботы.", type: "lu" },
        ],
        maxChoices: 2,
      },
      {
        id: 19,
        text: "Представьте, пожалуйста, что Вы сейчас ищете работу. Вам предлагают несколько работ. Какую из них Вы выберете? Можете дать ОДИН ИЛИ ДВА варианта ответа:",
        options: [
          { id: "1", text: "Наиболее интересную, творческую.", type: "pr" },
          {
            id: "2",
            text: "Наиболее самостоятельную, независимую.",
            type: "ow",
          },
          { id: "3", text: "За которую больше платят.", type: "in" },
          {
            id: "4",
            text: "Чтобы за не слишком большие деньги не требовалось особенно «надрываться».",
            type: "lu",
          },
          {
            id: "5",
            text: "Не могу представить, что я уйду из нашей организации.",
            type: "pa",
          },
        ],
        maxChoices: 2,
      },
      {
        id: 20,
        text: "Что Вы, прежде всего, учитываете, когда оцениваете успехи другого работника в Вашей организации? Можете дать ОДИН ИЛИ ДВА варианта ответа:",
        options: [
          {
            id: "1",
            text: "Его зарплату, доходы, материальное положение.",
            type: "in",
          },
          {
            id: "2",
            text: "Уровень его профессионализма, квалификации.",
            type: "pr",
          },
          { id: "3", text: "Насколько хорошо он «устроился».", type: "lu" },
          { id: "4", text: "Насколько его уважают в организации.", type: "pa" },
          {
            id: "5",
            text: "Насколько он самостоятелен, независим.",
            type: "ow",
          },
        ],
        maxChoices: 2,
      },
      {
        id: 21,
        text: "Если положение в Вашей организации ухудшится, на какие перемены в Вашей работе и положении Вы согласитесь ради того, чтобы остаться на работе? Можете дать СКОЛЬКО УГОДНО ответов:",
        options: [
          {
            id: "1",
            text: "Освоить новую профессию.",
            type: ["pr", "pa", "ow"],
          },
          {
            id: "2",
            text: "Работать неполный рабочий день или перейти на менее квалифицированную работу и меньше получать.",
            type: ["pa", "ow", "lu"],
          },
          { id: "3", text: "Перейти на менее удобный режим работы." },
          {
            id: "4",
            text: "Работать более интенсивно.",
            type: ["in", "pr", "pa", "ow"],
          },
          {
            id: "5",
            text: "Соглашусь просто терпеть, потому что деваться некуда.",
            type: ["pa", "lu"],
          },
          {
            id: "6",
            text: "Скорее всего, я просто уйду из этой организации.",
            type: "in"
          },
        ],
        maxChoices: 6,
      },
      {
        id: 22,
        text: "Если Вы - РУКОВОДИТЕЛЬ, то что Вас привлекает, прежде всего, в этом положении? Можете дать ОДИН ИЛИ ДВА варианта ответа:",
        options: [
          {
            id: "1",
            text: "Возможность принимать самостоятельные, ответственные решения.",
            type: "ow",
          },
          {
            id: "2",
            text: "Возможность принести наибольшую пользу организации.",
            type: "pa",
          },
          { id: "3", text: "Высокий уровень оплаты.", type: "in" },
          {
            id: "4",
            text: "Возможность организовывать работу других людей.",
            type: "lu",
          },
          {
            id: "5",
            text: "Возможность наилучшим образом применить свои знания и умения.",
            type: "pr",
          },
          {
            id: "6",
            text: "Ничего особенно не привлекает, за положение руководителя не держусь.",
            type: "in",
          },
        ],
        maxChoices: 2,
      },
      {
        id: 23,
        text: "Если Вы НЕ ЯВЛЯЕТЕСЬ РУКОВОДИТЕЛЕМ, то хотели бы Вы им стать? Можете дать ОДИН ИЛИ ДВА варианта ответа:",
        options: [
          {
            id: "1",
            text: "Да, поскольку это даст возможность принимать самостоятельные, ответственные решения.",
            type: "ow",
          },
          {
            id: "2",
            text: "Не против, если это нужно для пользы дела.",
            type: "pa",
          },
          {
            id: "3",
            text: "Да, так как при этом я смогу лучше применить свои знания и умения.",
            type: ["pr", "ow"],
          },
          {
            id: "4",
            text: "Да, если это будет должным образом оплачиваться.",
            type: "in",
          },
          {
            id: "5",
            text: "Нет, профессионал может отвечать только за самого себя.",
            type: "pr",
          },
          {
            id: "6",
            text: "Нет, руководство меня не привлекает, а хорошо заработать я могу и на своем месте.",
            type: "in",
          },
          { id: "7", text: "Да, чем я хуже других?", type: "lu" },
          {
            id: "8",
            text: "Нет, это слишком большая нагрузка для меня.",
            type: "lu",
          },
        ],
        maxChoices: 2,
      },
    ],
  },
];

export const motColumns = [
  "Инструментальный тип",
  "Профессиональный тип",
  "Патриотический тип",
  "Хозяйский тип",
  "Люмпензированный тип",
];

export const sendInfo = {
  serviceId: "service_w0p654s",
  templateId: "template_t1c3bp4",
  publicKey: "5ztFKSbFCws8OwMkK",
};
