import React from 'react';
import Navbar from '../components/Navbar';
import JovashiForm from '../components/JovashiTest/JovashiForm';

const JovashiTestPage = () => {
  return (
    <div className="background">
      <Navbar />
      <JovashiForm />
    </div>
  );
};

export default JovashiTestPage;