import { motColumns, trudMotivTestData } from "../../../data/TrudMotivTestData";

export const trudMotivTestAnalysis = (answers) => {
  const result = {
    in: 0, // Инструментальный
    pr: 0, // Профессиональный
    pa: 0, // Патриотический
    ow: 0, // Хозяйский
    lu: 0, // Люмпенизированный
  };

  Object.entries(answers).forEach(([questionId, selectedOptionIds]) => {
    // Найти вопросы в тесте
    const question = trudMotivTestData
      .find((q) => q.id === "questions")
      ?.questions.find((q) => q.id === Number(questionId));

    if (question) {
      selectedOptionIds.forEach((optionId) => {
        // Найти сам вариант ответа
        const option = question.options.find((opt) => opt.id === optionId);
        if (option && option.type) {
          const types = Array.isArray(option.type)
            ? option.type
            : [option.type];
          types.forEach((type) => {
            if (result[type] !== undefined) {
              result[type] += 1;
            }
          });
        }
      });
    }
  });

  return motColumns
    .map((type, index) => `${type}: ${Object.values(result)[index]}`)
    .join("\n");
};
