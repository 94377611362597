import { jovashiTestData } from '../../../data/JovashiTestData';

export const jovashiTestAnalysis = (answers) => {
  const result = {
    I: 0,
    II: 0,
    III: 0,
    IV: 0,
    V: 0,
    VI: 0,
  };

  Object.entries(answers).forEach(([questionId, selectedOptions]) => {
    const question = jovashiTestData.find((q) => q.id === Number(questionId));
    if (!question) return; 

    selectedOptions.forEach((optionId) => {

      const option = question.options.find((opt) => opt.id === optionId);
      console.log(option)
      if (option && result.hasOwnProperty(option.type)) {
        result[option.type] += 1;
      }
    });
  });

  return result;
};