export const hollandTestAnalysis = (answers) => {
  const result = {
    Реалистический: 0,
    Интеллектуальный: 0,
    Социальный: 0,
    Офисный: 0,
    Предприимчивый: 0,
    Артистический: 0,
  };

  Object.values(answers).forEach((answer) => {
    if (answer.type) {
      result[answer.type] += 1;
    }
  });

  return result;
};