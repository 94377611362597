import React from "react";

const QuestionSection = ({ section, handleAnswerChange }) => (
  <div className="space-y-6">
    {section.questions.map((question) => (
      <React.Fragment key={question.id}>
        <div className="bg-white p-4 md:p-6 rounded-2xl shadow-md border border-gray-200">
          <p className="font-semibold text-lg md:text-xl mb-3">
            {question.id}. {question.text}
          </p>
          <div className="space-y-2">
            {question.options.map((option) => (
              <label
                key={option.id}
                className="flex items-center space-x-3 bg-gray-100 p-3 rounded-lg hover:bg-gray-200 transition cursor-pointer"
              >
                <input
                  type="radio"
                  name={`question-${question.id}`}
                  value={option.id}
                  onChange={() => handleAnswerChange(question.id, option)}
                  className="formInputLeftMargin"
                  required
                />
                <span className="text-base md:text-lg text-gray-700 leading-snug">
                  {option.text}
                </span>
              </label>
            ))}
          </div>
        </div>
      </React.Fragment>
    ))}
  </div>
);

export default QuestionSection;
