import React, { useState } from "react";
import { sendInfo, trudMotivTestData } from "../../data/TrudMotivTestData";
import { trudMotivTestAnalysis } from "./helpers/trudMotivTestAnalysis";
import QuestionSection from "./QuestionSection";
import TextInputSection from "./TextInputSection";
import sendEmail from "../../helpers/sendEmail";
import { useNavigate } from "react-router-dom";

const TrudMotivForm = () => {
  const [answers, setAnswers] = useState({});
  const [fio, setFio] = useState("");
  const test = "Определение типов трудовой мотивации";
  const navigate = useNavigate();

  const handleAnswerChange = (questionId, optionId) => {
    setAnswers((prevAnswers) => {
      const currentAnswers = prevAnswers[questionId] || [];
      const isSelected = currentAnswers.includes(optionId);

      if (
        trudMotivTestData
          .find((q) => q.id === "questions")
          ?.questions.find((q) => q.id === questionId)?.maxChoices > 1
      ) {
        if (
          !isSelected &&
          currentAnswers.length >=
            trudMotivTestData
              .find((q) => q.id === "questions")
              ?.questions.find((q) => q.id === questionId)?.maxChoices
        ) {
          alert("Максимальное количество выбранных вариантов достигнуто.");
          return prevAnswers;
        }

        return {
          ...prevAnswers,
          [questionId]: isSelected
            ? currentAnswers.filter((id) => id !== optionId)
            : [...currentAnswers, optionId],
        };
      }

      return {
        ...prevAnswers,
        [questionId]: isSelected ? [] : [optionId], // Для radio – заменяем
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!fio.trim()) {
      alert("Пожалуйста, введите ваше ФИО.");
      return;
    }

    const unansweredQuestions = trudMotivTestData.filter(
      (section) =>
        section.type === "multiCheckbox" &&
        section.questions.some(
          (question) =>
            !answers[question.id] || answers[question.id].length === 0,
        ),
    );

    if (unansweredQuestions.length > 0) {
      alert("Пожалуйста, ответьте на все вопросы.");
      return;
    }

    const result = trudMotivTestAnalysis(answers);

    try {
      await sendEmail(test, fio, result, sendInfo);
      navigate("/finish");
    } catch (error) {
      alert(`Ошибка отправки: ${error.text}`);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-xl font-semibold text-center mb-6">{test}</h1>
      <form onSubmit={handleSubmit}>
        {trudMotivTestData.map((section) =>
          section.type === "multiCheckbox" ? (
            <QuestionSection
              key={section.id}
              section={section}
              answers={answers}
              onAnswerChange={handleAnswerChange}
            />
          ) : (
            <TextInputSection
              key={section.id}
              section={section}
              fio={fio}
              setFio={setFio}
            />
          ),
        )}
        <button
          type="submit"
          className="w-full text-lg  bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md mt-4"
        >
          Отправить
        </button>
      </form>
    </div>
  );
};
export default TrudMotivForm;
