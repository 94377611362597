import React from "react";
import Navbar from "../components/Navbar";
import TrudMotivForm from "../components/TrudMotivTest/TrudMotivForm";

const TrudMotivTestPage = () => {
  return (
    <div className="background">
      <Navbar />
      <TrudMotivForm />
    </div>
  );
};

export default TrudMotivTestPage;
