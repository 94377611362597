import React, { useState } from "react";
import { hollandTestAnalysis } from "../Form/helpers/hollandTestAnalysis"; // Исправленный путь
import { hollandTestData, sendInfo } from "../../data/HollandTestData";
import QuestionSection from "./QuestionSection";
import TextInputSection from "./TextInputSection";
import sendEmail from "../../helpers/sendEmail";
import { useNavigate } from "react-router-dom";

const HollandForm = () => {
  const [answers, setAnswers] = useState({});
  const [fio, setFio] = useState(""); // Состояние для ФИО
  const test = "Определение профессионального типа личности";
  const navigate = useNavigate();

  const handleAnswerChange = (questionId, option) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: option,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Проверка, что ФИО заполнено
    if (!fio.trim()) {
      alert("Пожалуйста, введите ваше ФИО.");
      return;
    }

    // Проверка, что все вопросы заполнены
    const unansweredQuestions = hollandTestData
      .filter((section) => section.type === "multiCheckbox")
      .flatMap((section) => section.questions)
      .filter((question) => !answers[question.id]);

    if (unansweredQuestions.length > 0) {
      alert("Пожалуйста, ответьте на все вопросы.");
      return;
    }

    // Подсчет результатов
    const value = hollandTestAnalysis(answers);

    const result = `
        Реалистический: ${value.Реалистический}
        Интеллектуальный: ${value.Интеллектуальный}
        Социальный: ${value.Социальный}
        Офисный: ${value.Офисный}
        Предприимчивый: ${value.Предприимчивый}
        Артистический: ${value.Артистический}
      `;

    try {
      await sendEmail(test, fio, result, sendInfo);
      navigate("/finish");
    } catch (error) {
      alert(`Ошибка отправки: ${error.text}`);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-xl font-semibold text-center mb-6">{test}</h1>
      <form onSubmit={handleSubmit}>
        {hollandTestData.map((section) =>
          section.type === "multiCheckbox" ? (
            <QuestionSection
              key={section.id}
              section={section}
              handleAnswerChange={handleAnswerChange}
            />
          ) : (
            <TextInputSection
              key={section.id}
              section={section}
              fio={fio}
              setFio={setFio}
            />
          ),
        )}
        <button
          type="submit"
          className="w-full text-lg  bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md mt-4"
        >
          Отправить
        </button>
      </form>
    </div>
  );
};

export default HollandForm;
