import React, { useState } from "react";
import { jovashiTestData, sendInfo } from "../../data/JovashiTestData";
import { jovashiTestAnalysis } from "../Form/helpers/jovashiTestAnalysis";
import UserDataForm from "./UserDataForm";
import QuestionForm from "./QuestionForm";
import sendEmail from "../../helpers/sendEmail";
import { useNavigate } from "react-router-dom";

const JovashiForm = () => {
  const [answers, setAnswers] = useState({});
  const [fio, setFio] = useState("");
  const test = "Определение профессиональных склонностей (для учащихся)";
  const navigate = useNavigate();

  const handleAnswerChange = (questionId, optionId) => {
    setAnswers((prevAnswers) => {
      const question = jovashiTestData.find((q) => q.id === questionId);

      if (question.maxChoices === 1) {
        return {
          ...prevAnswers,
          [questionId]: [optionId],
        };
      }

      const currentAnswers = prevAnswers[questionId] || [];
      const isSelected = currentAnswers.includes(optionId);

      if (isSelected) {
        return {
          ...prevAnswers,
          [questionId]: currentAnswers.filter((id) => id !== optionId),
        };
      }

      if (currentAnswers.length < question.maxChoices) {
        return {
          ...prevAnswers,
          [questionId]: [...currentAnswers, optionId],
        };
      }

      return prevAnswers;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!fio.trim()) {
      alert("Пожалуйста, введите ваше ФИО.");
      return;
    }

    const unansweredQuestions = jovashiTestData.filter((question) => {
      const questionAnswers = answers[question.id] || [];
      return questionAnswers.length === 0;
    });

    if (unansweredQuestions.length > 0) {
      alert(
        `Пожалуйста, ответьте на все вопросы. Неотвеченные вопросы: ${unansweredQuestions.map((q) => q.id).join(", ")}`,
      );
      return;
    }

    const value = jovashiTestAnalysis(answers);

    const result = `
      I. Склонность к работе с людьми: ${value.I}
      II. Склонность к исследовательской работе: ${value.II}
      III. Склонность к практической деятельности: ${value.III}
      IV. Склонность к эстетическим видам деятельности: ${value.IV}
      V. Склонность к экстремальным видам деятельности: ${value.V}
      VI. Склонность к планово-экономическим видам деятельности: ${value.VI}
    `;

    try {
      await sendEmail(test, fio, result, sendInfo);
      navigate("/finish");
    } catch (error) {
      alert(`Ошибка отправки: ${error.text}`);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-xl font-semibold text-center mb-6">{test}</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Блок с ФИО */}
        <UserDataForm fio={fio} setFio={setFio} />

        {/* Вопросы теста */}
        {jovashiTestData.map((question) => (
          <QuestionForm
            key={question.id}
            question={question}
            selectedAnswers={answers[question.id] || []}
            onAnswerChange={handleAnswerChange}
          />
        ))}

        <button
          type="submit"
          className="w-full text-lg bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md mt-4"
        >
          Отправить
        </button>
      </form>
    </div>
  );
};

export default JovashiForm;
