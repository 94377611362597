import emailjs from "emailjs-com";

const sendEmail = (test, fio, result, sendInfo) => {
  const templateParams = {
    test,
    fio,
    result,
  };

  return emailjs.send(
    sendInfo.serviceId,
    sendInfo.templateId,
    templateParams,
    sendInfo.publicKey,
  );
};

export default sendEmail;
